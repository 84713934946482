<template>
  <dialogComp
    title="新增服务站"
    width="740px"
    :isShow="isShow"
    @close="close"
    @sure="sure"
  >
    <el-form
      :model="formData"
      :rules="rules"
      ref="formData"
      label-width="120px"
    >
      <div class="flex">
        <inputFormComp
          label="服务站名称:"
          prop="name"
          :hval="formData.name"
          @blur="nameBlur"
          placeholder="请输入服务站名称"
        />
        <selectFormComp
          label="合作类型:"
          prop="cooperationMode"
          placeholder="请选择合作类型"
          :data="cooperationModeList"
          :hval="formData.cooperationMode"
          @change="cooperationModeChange"
        />
      </div>
      <div class="flex">
        <el-form-item label="区域:" prop="area">
          <areaData :hval="formData.area" @change="areaChange" />
        </el-form-item>
        <inputFormComp
          label="详细地址:"
          prop="detailAddress"
          :hval="formData.detailAddress"
          @blur="detailAddressBlur"
          placeholder="请输入详细地址"
        />
      </div>
      <div class="flex">
        <selectFormComp
          label="开票类型:"
          prop="billingType"
          placeholder="请选择开票类型"
          :data="billingTypeList"
          :hval="formData.billingType"
          @change="billingTypeChange"
        />
        <selectFormComp
          label="结算周期:"
          prop="settlementCycle"
          placeholder="请选择结算周期"
          :data="settlementCycleList"
          :hval="formData.settlementCycle"
          @change="settlementCycleChange"
        />
      </div>
      <div class="flex">
        <selectFormComp
          label="是否连锁:"
          prop="chain"
          placeholder="请选择是否连锁"
          :data="chainList"
          :hval="formData.chain"
          @change="chainChange"
        />
        <el-form-item
          v-if="formData.chain === 3"
          label="主站:"
          prop="masterStationId"
        >
          <el-select
            v-model="formData.masterStationName"
            placeholder="主站模糊搜索"
            filterable
            size="small"
            reserve-keyword
            :remote-method="remoteMethod"
            remote
            @change="masterStationChange"
          >
            <el-option
              v-for="item in masterStationList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </div>
      <div class="flex">
        <selectFormComp
          label="接单状态:"
          prop="status"
          placeholder="请选择接单状态"
          :data="statusList"
          :hval="formData.status"
          @change="statusChange"
        />
      </div>
      <div style="height:1px;width:100%;background:#ccc;margin-bottom:20px"></div>
      <div class="flex">
        <inputFormComp
          label="姓名:"
          prop="userName"
          :hval="formData.userName"
          @blur="userNameBlur"
          placeholder="请输入姓名"
        />
        <inputFormComp
          label="手机号/固话:"
          prop="mobile"
          :hval="formData.mobile"
          @blur="mobileBlur"
          placeholder="请输入手机号/固话"
        />
      </div>
      <div class="flex">
        <selectFormComp
          label="角色:"
          prop="businessRole"
          placeholder="请选择角色"
          :data="businessRoleList"
          :hval="formData.businessRole"
          @change="businessRoleChange"
        />
        <radioFormComp
          label="性别:"
          prop="sex"
          style="width: 55%"
          :radioList="sexList"
          :hval="formData.sex"
          @change="sexChange"
        />
      </div>
    </el-form>
  </dialogComp>
</template>
<script>
import areaData from '@/views/components/areaDataNew'
import constList from '@/mixin/constList'
import { serviceStatAdd, serviceStatList } from '@/api'
export default {
  name: '',
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    tableData: {
      type: Array,
      default: () => []
    }
  },
  components: { areaData },
  mixins: [constList],
  data () {
    return {
      masterStationList: [],
      storeNameList: [],
      formData: {
        name: '',
        cooperationMode: '',
        detailAddress: '',
        billingType: '',
        settlementCycle: '',
        status: '',
        area: [],
        province: '',
        city: '',
        region: '',
        chain: '',
        masterStationId: '',
        masterStationName: '',
        userName: '',
        mobile: '',
        businessRole: 1,
        sex: 1
      },
      businessRoleList: [
        { label: '默认负责人', value: 1 },
        { label: '负责人', value: 2 },
        { label: '成员', value: 3 }
      ],
      sexList: [
        { label: '男', value: 1 },
        { label: '女', value: 2 }
      ],
      rules: {
        name: [
          { required: true, message: '请输入服务站名称', trigger: 'blur' }
        ],
        area: [{ required: true, message: '请选择区域', trigger: 'blur' }],
        cooperationMode: [
          { required: true, message: '请选择合作类型', trigger: 'change' }
        ],
        detailAddress: [
          { required: true, message: '请输入详细地址', trigger: 'blur' }
        ],
        billingType: [
          { required: true, message: '请选择开票类型', trigger: 'change' }
        ],
        settlementCycle: [
          { required: true, message: '请选择结算周期', trigger: 'change' }
        ],
        status: [
          { required: true, message: '请选择接单状态', trigger: 'change' }
        ],
        chain: [
          { required: true, message: '请选择是否连锁', trigger: 'change' }
        ],
        masterStationId: [
          { required: true, message: '请选择主站', trigger: 'change' }
        ]
        // mobile: [
        //   { required: true, message: '请输入手机号', trigger: 'blur' },
        //   { validator: checkMobile, trigger: 'blur' }
        // ]
      }
    }
  },
  methods: {
    // 服务站连锁下拉列表
    getServiceStatList (name) {
      serviceStatList({ page: 1, size: 100, chain: 2, name }).then(res => {
        this.masterStationList = res.data.list
      })
    },
    // 远程搜索
    remoteMethod (query) {
      this.getServiceStatList(query)
    },
    // 服务站选择
    masterStationChange (val) {
      this.formData.masterStationId = val
    },
    nameBlur (val) {
      this.formData.name = val
    },
    cooperationModeChange (val) {
      this.formData.cooperationMode = val
    },
    areaChange (val) {
      this.formData.area = val
      const [a, b, c] = val
      this.formData.province = a || ''
      this.formData.city = b || ''
      this.formData.region = c || ''
    },
    detailAddressBlur (val) {
      this.formData.detailAddress = val
    },
    billingTypeChange (val) {
      this.formData.billingType = val
    },
    settlementCycleChange (val) {
      this.formData.settlementCycle = val
    },
    statusChange (val) {
      this.formData.status = val
    },
    chainChange (val) {
      this.formData.chain = val
      this.formData.masterStationId = ''
      this.formData.masterStationName = ''
    },
    userNameBlur (val) {
      this.formData.userName = val
    },
    mobileBlur (val) {
      this.formData.mobile = val
    },
    businessRoleChange (val) {
      this.formData.businessRole = val
    },
    sexChange (val) {
      this.formData.sex = val
    },
    close () {
      this.formData.area = []
      this.$refs.formData.resetFields()
      this.$emit('close')
    },
    sure () {
      this.$refs.formData.validate(valid => {
        if (valid) {
          const {
            userName,
            mobile,
            businessRole,
            sex,
            name,
            cooperationMode,
            detailAddress,
            billingType,
            settlementCycle,
            status,
            province,
            city,
            region,
            chain,
            masterStationId,
            masterStationName
          } = this.formData
          if (userName && !mobile) {
            this.$message.error('填了姓名必须填写手机号/固话')
            return
          } else if (userName && mobile) {
            var telReg = /^1[3456789]\d{9}$/
            var phoneReg = new RegExp(/^\d{3}-\d{6,8}|\d{4}-\d{6,8}$/)
            if (!telReg.test(mobile) && !phoneReg.test(mobile)) {
              this.$message.error('填的手机号/固话格式不正确')
              return
            }
          }
          const params = {
            member: { name: userName, mobile, businessRole, sex },
            station: {
              name,
              cooperationMode,
              detailAddress,
              billingType,
              settlementCycle,
              status,
              province,
              city,
              region,
              chain,
              masterStationId,
              masterStationName
            }
          }
          serviceStatAdd(params).then((res) => {
            this.$message.success('添加成功')
            this.close()
          })
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped></style>
