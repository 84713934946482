<template>
  <div class="layout_common station_info">
    <div class="main_box">
      <div class="main_box_con">
        <div class="search_flex">
          <div class="search_left">
            <inputComp placeholder="服务站名称" :hval="name" @blur="nameBlur" />
            <inputComp placeholder="人员名称" :hval="personnel" @blur="personnelBlur" />
            <inputComp placeholder="人员电话" :hval="mobile" @blur="mobileBlur" />
            <selectComp
              placeholder="接单状态"
              :data="statusList"
              :hval="status"
              @change="statusChange"
            />
            <selectComp
              placeholder="合作类型"
              :data="cooperationModeList"
              :hval="cooperationMode"
              @change="cooperationModeChange"
            />
            <areaData @change="areaChange" :hval="area" />
            <inputComp
              placeholder="地址"
              :hval="detailAddress"
              @blur="detailAddressBlur"
            />
            <btnGroup @search="search" @reset="reset" />
            <btnComp @click="add" v-permission="'新增'" >新 增</btnComp>
          </div>
        </div>
        <tableComp
          :data="tableData"
          :col-configs="theadName"
          :total="total"
          :currentPage="curPage"
          :pageSize="pageSize"
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
          v-loading="loading"
        >
          <el-table-column slot="name" label="服务站名称">
            <template v-slot="scope">
              <router-link
                v-permission="'详情'"
                :to="{ name: 'station-detail', query: { id: scope.row.id,chain: scope.row.chain} }"
              >
                {{ scope.row.name }}
              </router-link>
              <div v-show="!flag">{{ scope.row.name }}</div>
            </template>
          </el-table-column>
           <el-table-column slot="operate" label="操作" fixed="right">
            <template v-slot="scope">
              <btnComp btnType="text" style="color:red" @click="del(scope.row)" v-permission="'删除'"
                >删除</btnComp
              >
            </template>
          </el-table-column>
        </tableComp>
        <add :isShow="addShow" @close="close" />
      </div>
    </div>
     <dialogComp
        title="提示"
        :isShow="hintShow"
        @close="close"
        @sure="hintSure"
      >
      <p>确定删除吗？</p>
    </dialogComp>
  </div>
</template>
<script>
import add from './add'
import areaData from '@/views/components/areaDataNew'
import constList from '@/mixin/constList'
import { serviceStatList, serviceStatDel } from '@/api'
import { btnPermission } from '@/utils/directive'
export default {
  name: '',
  props: {},
  components: { areaData, add },
  mixins: [constList],
  data () {
    return {
      loading: false,
      addShow: false,
      hintShow: false,
      curPage: 1,
      pageSize: 10,
      total: 0,
      name: '',
      personnel: '',
      mobile: '',
      detailAddress: '',
      cooperationMode: '',
      status: '',
      area: [],
      province: '',
      city: '',
      region: '',
      tableData: [],
      theadName: [
        { prop: 'createdTime', label: '注册日期', width: 160 },
        { slot: 'name' },
        { prop: 'statusName', label: '接单状态' },
        { prop: 'cooperationModeName', label: '合作模式' },
        { prop: 'storeName', label: '商家' },
        { prop: 'chainType', label: '连锁' },
        { prop: 'provinceCityRegion', label: '区域' },
        { prop: 'detailAddress', label: '详细地址' },
        { prop: 'defaultResponsible', label: '负责人' },
        { prop: 'billingTypeName', label: '开票类型' },
        { prop: 'number', label: '人数' },
        { prop: 'orderNumber', label: '接单量' },
        { slot: 'operate' }
      ]
    }
  },
  mounted () {
    this.getList()
  },
  computed: {
    // 来判断是否有详情权限
    flag () {
      return btnPermission(this.$store.state.menuList, this.$route.path, '详情')
    }
  },
  methods: {
    areaChange (val) {
      const [a, b, c] = val
      this.area = val
      this.province = a || ''
      this.city = b || ''
      this.region = c || ''
    },
    getList () {
      const params = {
        curPage: this.curPage,
        pageSize: this.pageSize,
        name: this.name,
        personnel: this.personnel,
        mobile: this.mobile,
        detailAddress: this.detailAddress,
        cooperationMode: this.cooperationMode,
        status: this.status,
        province: this.province,
        city: this.city,
        region: this.region
      }
      this.loading = true
      serviceStatList(params).then((res) => {
        this.loading = false
        this.tableData = res.data.list
        this.total = res.data.totalCount
      })
    },
    nameBlur (val) {
      this.name = val
    },
    personnelBlur (val) {
      this.personnel = val
    },
    mobileBlur (val) {
      this.mobile = val
    },
    detailAddressBlur (val) {
      this.detailAddress = val
    },
    statusChange (val) {
      this.status = val
    },
    cooperationModeChange (val) {
      this.cooperationMode = val
    },
    search () {
      this.curPage = 1
      this.getList()
    },
    add () {
      this.addShow = true
    },
    // 删除操作
    del (row) {
      this.id = row.id
      this.hintShow = true
    },
    hintSure () {
      serviceStatDel({ id: this.id }).then(_ => {
        this.$message.success('删除成功')
        this.close()
      })
    },
    reset () {
      this.curPage = 1
      this.name = ''
      this.personnel = ''
      this.mobile = ''
      this.detailAddress = ''
      this.cooperationMode = ''
      this.status = ''
      this.province = ''
      this.city = ''
      this.region = ''
      this.area = []
      this.getList()
    },
    close () {
      this.addShow = false
      this.hintShow = false
      this.getList()
    },
    handleSizeChange (val) {
      this.pageSize = val
      this.getList()
    },
    handleCurrentChange (val) {
      this.curPage = val
      this.getList()
    }
  }
}
</script>
<style lang='scss' scoped>
.btnList {
  margin-top: 20px;
}
</style>
